import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-sdk"
    }}>{`Getting Started SDK`}</h1>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body>
  <script
    type="text/javascript"
    async
    id="oli-sdk"
    src="https://sdk.oli.live/oli-sdk.js"
  ></script>
</body>
`}</code></pre>
    <h2 {...{
      "id": "usageexamples"
    }}>{`Usage/Examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<body>
  <script type="text/javascript">
    window.addEventListener('load', ()=>{
        oli.init({liveId: '00000000-1111-2222-3333-444444444444'});
    });
  </script>
</body>
`}</code></pre>
    <h4 {...{
      "id": "or"
    }}>{`Or`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<body>
  <script type="text/javascript">
    function initializeOli() {
      oli.init({ liveId: "00000000-1111-2222-3333-444444444444" });
    }
    document.getElementById("oli-sdk").addEventListener("load", initializeOli);
  </script>
</body>
`}</code></pre>
    <h2 {...{
      "id": "sdk-reference"
    }}>{`SDK Reference`}</h2>
    <h3 {...{
      "id": "methods"
    }}>{`Methods`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Function Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Params Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Return Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`init`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#init-options"
            }}><inlineCode parentName="a">{`Init Options`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Initialize Oli`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "init-options"
    }}>{`Init Options`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`liveId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Required`}</strong>{`. Your SDK key`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      